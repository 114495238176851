import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDhhjacCF3oHdjbouRqscHCzZNEhreHFbI",
  authDomain: "sphinx-db.firebaseapp.com",
  projectId: "sphinx-db",
  storageBucket: "sphinx-db.appspot.com",
  messagingSenderId: "642950546989",
  appId: "1:642950546989:web:9e212f631e6fc6463d9aa4"

};

const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const db = app.firestore();

export { auth, db };
