import React, { useState, useContext } from 'react';
import { db} from '../firebase';
import  { useNavigate } from 'react-router-dom'
import { UserContext } from '../context/UserContext';
import firebase from 'firebase/compat/app';

const CreateEnigma = () => {
  const navigate = useNavigate()
  const { user } = useContext(UserContext);
  const [content, setContent] = useState('');
  const [response, setResponse] = useState('');
  const [title, setTitle] = useState('');
  const [show_date, setDate] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await db.collection('enigmas').add({
        title,
        content,
        response : response.toLowerCase(),
        first_finisher: null,
        show_date : firebase.firestore.Timestamp.fromDate(new Date(show_date)),
      });
      navigate("/")
    } catch (error) {
      console.error('Error creating enigma:', error);
    }
  };

  if(!user || !user.isAdmin){
    navigate("/login")
  }

  return (
    <div className="page-container">
      <h2>Créer une nouvelle énigme</h2>
      <form onSubmit={handleSubmit}>
      <input
          type="text"
          placeholder="Titre"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <textarea
          placeholder="Contenu de l'énigme"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <input
          type="text"
          placeholder="Réponse"
          value={response}
          onChange={(e) => setResponse(e.target.value)}
        />
        <input
          type="date"
          value={show_date}
          onChange={(e) => setDate(e.target.value)}
        />
        <button type="submit">Créer l'énigme</button>
      </form>
    </div>
  );
};

export default CreateEnigma;
