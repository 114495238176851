import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { db, auth } from '../firebase';
import { UserContext } from '../context/UserContext';
import firebase from 'firebase/compat/app';
import CryptoJS from 'crypto-js';

const EnigmaDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, reloadUser } = useContext(UserContext);
  const [userAnswer, setUserAnswer] = useState('');
  const [enigma, setEnigma] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [response, setResponse] = useState('');
  const [show_date, setDate] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchEnigma = async () => {
      try {
        const enigmaDoc = await db.collection('enigmas').doc(id).get();
        if (enigmaDoc.exists) {
          const enigmaData = enigmaDoc.data();
          setEnigma(enigmaData);
          setTitle(enigmaData.title);
          setContent(enigmaData.content);
          setResponse(enigmaData.response);
          setDate(enigmaData.show_date.toDate().toISOString().split('T')[0]);
        } else {
          setMessage('Énigme non trouvée');
          navigate('/');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération de l\'énigme:', error);
        setMessage('Erreur lors de la récupération de l\'énigme');
      }
    };

    fetchEnigma();
  }, [id, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!enigma) return;

    const hashedUserAnswer = CryptoJS.SHA256(userAnswer.toLowerCase()).toString();
    const hashedCorrectAnswer = CryptoJS.SHA256(enigma.response).toString();

    if (hashedUserAnswer === hashedCorrectAnswer) {
      setMessage('Bonne réponse !');

      const user = auth.currentUser;
      if (user) {
        const userDocRef = db.collection('users').doc(user.uid);

        try {
          const userDoc = await userDocRef.get();

          if (!userDoc.exists) {
            await userDocRef.set({
              email: user.email,
              displayName: user.displayName,
              enigmesResolues: [id],
              points: 50
            });

          } else {
            await userDocRef.update({
              enigmesResolues: firebase.firestore.FieldValue.arrayUnion(id),
              points: firebase.firestore.FieldValue.increment(50),
            });
          }

          if (!enigma.first_finisher) {
            await db.collection('enigmas').doc(id).update({
              first_finisher: user.displayName,
            });
            await userDocRef.update({
              points: firebase.firestore.FieldValue.increment(50)
            });
          }
          await reloadUser(user);

        } catch (error) {
          console.error("Erreur lors de la mise à jour de l'utilisateur:", error);
          setMessage("Erreur lors de la mise à jour de l'utilisateur.");
        }
      }
    } else {
      setMessage('Mauvaise réponse, essaie encore.');
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await db.collection('enigmas').doc(id).update({
        title,
        content,
        response : response.toLowerCase(),
        show_date: firebase.firestore.Timestamp.fromDate(new Date(show_date)),
      });
      setMessage('Énigme mise à jour avec succès');
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'énigme:', error);
      setMessage('Erreur lors de la mise à jour');
    }
  };

  const isEnigmaResolved = () => {
    return user && user.enigmesResolues && user.enigmesResolues.includes(id);
  };

  const formatDateFR = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  if (!enigma) {
    return <div>{message || 'Chargement de l\'énigme...'}</div>;
  }

  return (
    <div className="page-container">
      <h2>Détail de l'énigme</h2>
      <p><strong>Titre :</strong> {enigma.title}</p><br/>
      <p><strong>Contenu de l'énigme :</strong> {enigma.content}</p><br/>
      <p><strong>Date de publication :</strong> {formatDateFR(enigma.show_date)}</p><br/>

      {enigma.first_finisher && <div><p>Premier à avoir résolu l'énigme : {enigma.first_finisher}</p><br/></div>}

      {user && !isEnigmaResolved() && (
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Votre réponse"
            value={userAnswer}
            onChange={(e) => setUserAnswer(e.target.value)}
          />
          <button type="submit">Soumettre la réponse</button>
        </form>
      )}

      {!user && <Link to='/login'>Vous devez vous connecter pour répondre.</Link>}

      {isEnigmaResolved() && <div><span> ✅ Vous avez résolu cette énigme !</span><br/><Link to="/">Retour</Link></div>}

      {message && <p>{message}</p>}

      {user && user.isAdmin && (
        <div className="admin-update-form">
          <br/>
          <h3>Modifier l'énigme (Administrateur)</h3><br/>
          <form onSubmit={handleUpdate}>
            <input
            type="text"
            placeholder="Titre"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            />
            <textarea
              placeholder="Contenu de l'énigme"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            <input
              type="text"
              placeholder="Réponse"
              value={response}
              onChange={(e) => setResponse(e.target.value)}
            />
            <input
              type="date"
              value={show_date}
              onChange={(e) => setDate(e.target.value)}
            />
            <button type="submit">Mettre à jour l'énigme</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default EnigmaDetail;
