import React, { createContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import CryptoJS from 'crypto-js';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const userDoc = await db.collection('users').doc(firebaseUser.uid).get();
          const userData = userDoc.data();

          setUser({
            uid: firebaseUser.uid,
            email: firebaseUser.email,
            displayName: firebaseUser.displayName,
            points: userData?.points,
            enigmesResolues: userData?.enigmesResolues || [],
            isAdmin: userData?.isAdmin || String(CryptoJS.SHA256(firebaseUser.email)) === "9b85dea93d9fd6d6548694092846a4be4183b9dc8fa3587c60b1ba71acf60347"
          });
        } catch (error) {
          console.error("Erreur lors de la récupération des données utilisateur:", error);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const reloadUser = async (firebaseUser) => {
    try {
      const userDoc = await db.collection('users').doc(firebaseUser.uid).get();
      const userData = userDoc.data();

      setUser({
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        displayName: firebaseUser.displayName,
        enigmesResolues: userData?.enigmesResolues || [],
        isAdmin: userData?.isAdmin || String(CryptoJS.SHA256(firebaseUser.email)) === "9b85dea93d9fd6d6548694092846a4be4183b9dc8fa3587c60b1ba71acf60347"  // Vérifie si l'utilisateur est admin
      });
    } catch (error) {
      console.error("Erreur lors de la récupération des données utilisateur:", error);
    }
  };

  return (
    <UserContext.Provider value={{ user, loading, reloadUser}}>
      {!loading && children}
    </UserContext.Provider>
  );
};