import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebase';
import { UserContext } from '../context/UserContext';
import firebase from 'firebase/compat/app';

const Home = () => {
  const [enigmas, setEnigmas] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchEnigmas = async () => {
      const now = firebase.firestore.Timestamp.now();
      let query;

      if (user && user.isAdmin) {
        query = db.collection('enigmas').orderBy('show_date', 'desc');
      } else {
        query = db.collection('enigmas')
          .where('show_date', '<=', now)
          .orderBy('show_date', 'desc');
      }

      const snapshot = await query.get();
      const enigmasList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEnigmas(enigmasList);
    };

    fetchEnigmas();
  }, [user]);

  const isEnigmaResolved = (enigmaId) => {
    return user && user.enigmesResolues && user.enigmesResolues.includes(enigmaId);
  };

  const isEnigmaUpcoming = (show_date) => {
    const now = firebase.firestore.Timestamp.now();
    return show_date > now;
  };

  const handleDelete = async (enigmaId) => {
    const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer cette énigme ?");
    if (confirmDelete) {
      try {
        await db.collection('enigmas').doc(enigmaId).delete();
        setEnigmas(enigmas.filter(enigma => enigma.id !== enigmaId));
      } catch (error) {
        console.error('Erreur lors de la suppression de l\'énigme:', error);
      }
    }
  };

  const formatDateFR = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  return (
    <div className="page-container">
      <h2>Liste des énigmes</h2>
      <ul>
        {enigmas.map(enigma => (
          <li key={enigma.id}>
            <Link to={`/enigma/${enigma.id}`}>{enigma.title}</Link>
            {isEnigmaResolved(enigma.id) && <span> ✅ </span>}
            {user && user.isAdmin && isEnigmaUpcoming(enigma.show_date) && <span> ⏳ - {formatDateFR(enigma.show_date)}</span>}
            {user && user.isAdmin && (
              <span> - <Link onClick={() => handleDelete(enigma.id)}>Supprimer</Link></span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Home;
