import React, { useState } from 'react';
import { auth } from '../firebase';
import  { Link, useNavigate } from 'react-router-dom'

const SignUp = () => {
  const navigate = useNavigate();
  const [nickname, setNickname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('')

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      if(!/^[a-zA-Z0-9_]+$/.test(nickname) || nickname.length < 3){
        setMessage("The nickname must have 3 char min and only letter, number or underscore.")
        return;
      }
      console.log("ça passe")
      const userCredential = await auth.createUserWithEmailAndPassword(email, password);
      const user = userCredential.user;
      await user.updateProfile({ displayName: nickname });
      navigate("/login")
    } catch (error) {
      setMessage(String(error).substring(String(error).lastIndexOf(":") + 1))
    }
  };

  return (
    <div className="page-container">
      <h2>Inscription</h2>
      {message && <div><p style={{color: "red"}}>{message}</p><br/></div>}
      <form onSubmit={handleSignUp}>
        <input
          type="text"
          placeholder="Pseudo"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Sign Up</button>
      </form><br/>
      <p>L'email permet l'identification, vous ne recevrez pas de mail.</p>
      <p>Vous pouvez en mettre un aléatoire mais il est nécessaire au login donc notez le.</p><br/>
      <p>Je n'ai pas accès à votre mot de passe.</p>
      <p>(Je vous encourage quand même à utiliser un gestionnaire de mot de passe gratuit comme <Link target="_blank" to="https://bitwarden.com/fr-fr/pricing/">Bitwarden</Link>).</p>
    </div>
  );
};

export default SignUp;
