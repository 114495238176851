import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import CreateEnigma from './pages/CreateEnigma';
import Leaderboard from './pages/Leaderboard';
import EnigmaDetail from './pages/EnigmaDetail';
import Navbar from './components/Navbar';
import { UserProvider } from './context/UserContext';

function App() {
  return (
    <UserProvider>
      <Router>
        <div className="App">
          <Navbar />
          <div className="content">
            <Routes>
              <Route path="/" exact Component={Home} />
              <Route path="/signup" Component={SignUp} />
              <Route path="/login" Component={Login} />
              <Route path="/enigma/create" Component={CreateEnigma} />
              <Route path="/enigma/:id" Component={EnigmaDetail} />
              <Route path="/leaderboard" Component={Leaderboard} />
            </Routes>
          </div>
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;