import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import { UserContext } from '../context/UserContext';

const Navbar = () => {
    const { user } = useContext(UserContext);

    return (
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo">
            Sphinx
          </Link>
          <ul className="nav-menu">
            <li className="nav-item">
              <Link to="/" className="nav-links">
                Accueil
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/leaderboard" className="nav-links">
                Classement
              </Link>
            </li>
            {user ? (
              <>
              {user.isAdmin && (
                <li className="nav-item">
                  <Link to="/enigma/create" className="nav-links">
                    Créer une énigme
                  </Link>
                </li>)}
                <li className="nav-item">
                  <Link onClick={() => auth.signOut() } className="nav-links">
                  Déconnexion
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <Link to="/signup" className="nav-links">
                    S'inscrire
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/login" className="nav-links">
                    Se connecter
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
    );
  };

export default Navbar;