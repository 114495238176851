import React, { useState, useEffect } from 'react';
import { db } from '../firebase';

const Leaderboard = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const snapshot = await db.collection('users').orderBy('points', 'desc').get();
      const usersList = snapshot.docs.map(doc => doc.data());
      setUsers(usersList);
    };
    fetchUsers();
  }, []);

  console.log(users)

  return (
    <div className="page-container">
      <h2>Classement</h2>
      <ul>
        {users.map((user, index) => (
          user.displayName !== "Sphinx" && <li key={index}>
          {user.displayName} : {user.points} points
        </li>
          
        ))}
      </ul>
    </div>
  );
};

export default Leaderboard;
